
 
body {
    font-family: "metropolisregular" !important;
    background: #fbfbfb !important;
    color: #424449 !important;
    overflow-y: hidden;
}

::-ms-backdrop,
body {
    overflow-x: hidden;
}

.tiers-tree-view .tree-node-level-2 .node-content-wrapper span.data-list,
.tree-node-level-2 .node-content-wrapper-focused span.data-list _:-ms-lang(x),
:-webkit-full-screen,
body {
    overflow-x: hidden;
}

.k-block ::selection,
.k-widget ::selection {
    background: #007bff
}

@font-face {
    font-family: 'metropolisregular';
    src: url('./assets/fonts/metropolis-regular-webfont.woff2') format('woff2'), url('./assets/fonts/metropolis-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent !important;
}

::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 10px;
}

::-webkit-input-placeholder {
    color: #C0C1C4;
}

::-moz-placeholder {
    color: #C0C1C4;
    font-size: 1.955vmin;
}

-ms-input-placeholder {
    color: #C0C1C4;
    font-size: 1.955vmin;
}

:-moz-placeholder {
    color: #C0C1C4;
    font-size: 1.955vmin;
}

.mat-mdc-dialog-container {
    background: #fff  !important;
    color: #000 !important;
}

button {
    background: #2f4573;
    border-collapse: collapse;
    border: #2f4573;
    color: #fff;
    padding: 8px;
    border: none;
}